/* Container for the video and overlay */
.home-container {
    position: relative;
    width: 100%;
    height: auto; /* Full screen height */
    overflow: hidden;
    z-index: 88;
  }
  
  /* Overlay Text */
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the text */
    text-align: center;
    color: white;
    z-index: 999 !important;
  }
  
  .overlay-text h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .overlay-text p {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
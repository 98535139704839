.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: to create a dim background */
}

.play-button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  transition: opacity 1s ease-in-out;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.Header {
  z-index: 1;
}

.Header, .main-content {
  display: none;
}

.show-content .Header,
.show-content .main-content {
  display: block;
}

.video-container {
  position: relative;
}

.video-background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10; /* Ensure it's on top of the video */
}

.cancel-button:hover {
  background: rgba(0, 0, 0, 0.7); /* Darker background on hover */
}
